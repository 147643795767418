import React from 'react';
import { Helmet } from 'react-helmet';

import { ButtonBase, Card, CardActionArea, CardContent, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { Link as Element } from 'react-scroll';
import { Link as LinkDOM } from 'react-router-dom';

import Footer from 'ui-component/Footer';

import Backdrop2_Join from '../../../assets/images/Backdrop2_Join.webp';
import Grafana_UPCARE2 from '../../../assets/images/Grafana_UPCARE2.webp'
import eTraffic3 from '../../../assets/images/eTraffic3.webp';
import SensorNodeDeployed4 from '../../../assets/images/SensorNodeDeployed4.webp'
import SensorNodeDeployed5 from '../../../assets/images/SensorNodeDeployed5.webp'
import DotsAccent from '../../../assets/images/DotsAccent.webp'
import DotsAccent2 from '../../../assets/images/DotsAccent2.webp'
import Icon_Project1 from '../../../assets/images/Icon_Project1.webp'
import DataVisualization from '../../../assets/images/DataVisualization.webp'

import For_Government from '../../../assets/images/For_Government.webp'
import For_Public from '../../../assets/images/For_Public.webp'
import For_Researchers from '../../../assets/images/For_Researchers.webp'

import { Box } from '@mui/system';
import Carousel from 'react-material-ui-carousel';

const People = () => {
    const isDesktopScreen = useMediaQuery('(min-width:960px)')

    const Benefits = [
        {
            name: 'Government',
            description: "Policy-making, decision-making, crafting and assessment of air quality programs, etc.",
            image: For_Government,
            flag: true,
        },
        {
            name: 'Researchers',
            description: "Better understanding of air pollution via data analytics, modeling applications, and further studies",
            image: For_Researchers,
        },
        {
            name: 'Public',
            description: "Personal exposure data, street-level air pollution maps, household indoor air quality monitoring, etc.",
            image: For_Public,
        },
    ]

    return (
        <>
            <Helmet>
                <title>Project 1 | UP CARE</title>
            </Helmet>
            <div style={{ overflowX: 'hidden' }}>
                <Element name="start">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 12,
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            background: `linear-gradient(180deg, rgba(0, 135, 141, 0.88) 33.33%, rgba(230, 158, 77, 0.70) 64.58%, rgba(0, 173, 181, 0.73) 100%)`,
                            position: 'relative',
                        }}
                    >
                        <Box
                            component='img'
                            src={DataVisualization}
                            sx={{
                                height: '100%',
                                opacity: 0.1,
                                top: '0',
                                right: '0',
                                transform: { xs: 'translateX(45%)', lg: 'none' },
                                clipPath: { xs: 'inset(0 45% 0 0)', lg: 'none' },
                                objectFit: 'cover',
                                display: 'block',
                                width: { xs: '210%', lg: '100%' },
                                alignContent: 'center',
                            }}
                            position='absolute'
                        />
                        <Grid container>
                            <Grid container xs={12} lg={6} padding={{ xs: 0, lg: '50px 5vw 40px 10vw' }} flexDirection='column'>
                                <Box padding={0}>
                                    <Box 
                                        component='img'
                                        src={Icon_Project1}
                                        sx={{ height: '56px', padding: '0 0 5px 10px' }}
                                    />
                                    <Typography 
                                        variant='h3' 
                                        fontSize='14px' 
                                        fontWeight={800}
                                        color='#FFF'
                                        textTransform='uppercase'
                                        padding='0 37px'
                                    >
                                        Project 1
                                    </Typography>
                                </Box>
                                <Box padding='21px 37px' display='flex' gap='19px' flexDirection='column'>
                                    <Typography variant='h3' color='#FFF' fontSize={{ xs: '20px', lg: '24px' }} fontWeight={{ xs: 800, lg: 1000 }}>
                                        Towards Green Smart Cities: {isDesktopScreen && <br />} Connected Embedded Systems {isDesktopScreen && <br />} for Indoor and Outdoor Air Quality
                                    </Typography>
                                    <Typography variant='h3' fontSize='14px' fontWeight={700} color='#FFF'>
                                        Lead: John Richard Hizon, PhD
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container xs={12} lg={4} margin={{ xs: 0, lg: '50px 0 50px 5vw' }} justifyContent={{ lg: 'center' }}>
                                <Box
                                    width='90%'
                                    sx={{ 
                                        background: 'rgba(0, 0, 0, 0.35)', 
                                        borderTopRightRadius: '5px', 
                                        borderBottomRightRadius: '5px',
                                        borderTopLeftRadius: { xs: 0, lg: '5px' },
                                        borderBottomLeftRadius: { xs: 0, lg: '5px' },
                                    }}
                                >
                                    <Typography
                                        variant='h3'
                                        color='#FFF'
                                        fontSize='20px'
                                        fontWeight={800}
                                        lineHeight='30px'
                                        letterSpacing='1px'
                                        padding={{ xs: '20px 33px 20px 48px', lg: '60px 33px 90px 33px' }}
                                        textAlign={{ lg: 'center' }}
                                    >
                                        Making the invisible visible {isDesktopScreen && <br />} through cutting-edge sensor network {isDesktopScreen && <br />} systems to monitor and enhance {isDesktopScreen && <br />} indoor/outdoor air quality {isDesktopScreen && <br />} in urban areas
                                    </Typography>
                                </Box>
                                <Box
                                    paddingLeft={{ xs: '37px', lg: 0 }}
                                    paddingTop={{ xs: '17px', lg: 0 }}
                                    sx={{ transform: { xs: 0, lg: 'translateY(-250%)' } }}
                                    display='flex'
                                    gap='8px'
                                    width={{ lg: '80%' }}
                                    justifyContent={{ lg: 'center' }}
                                >
                                    <Typography 
                                        variant='body1' 
                                        padding='6px 9px' 
                                        borderRadius='5px' 
                                        bgcolor='#E69E4D' 
                                        color='#FFF' 
                                        fontSize='11px' 
                                        fontWeight={400} 
                                        lineHeight='10px' 
                                        textTransform='uppercase'
                                    >
                                        On-Going
                                    </Typography>
                                    <Typography 
                                        variant='body1' 
                                        padding='6px 9px' 
                                        borderRadius='5px' 
                                        bgcolor='#E69E4D' 
                                        color='#FFF' 
                                        fontSize='11px' 
                                        fontWeight={400} 
                                        lineHeight='10px' 
                                        textTransform='uppercase'
                                    >
                                        TRL 5
                                    </Typography>
                                    <Typography 
                                        variant='body1' 
                                        padding='6px 9px' 
                                        borderRadius='5px' 
                                        bgcolor='#E69E4D' 
                                        color='#FFF' 
                                        fontSize='11px' 
                                        fontWeight={400} 
                                        lineHeight='10px' 
                                        textTransform='uppercase'
                                    >
                                        IP Protection In-Progress
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Element>
                <Element name="problem">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5,
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            boxShadow: { xs: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset', lg: 'none' },
                            pl: 0,
                            pr: 0,
                            display: 'inline-flex',
                            overflow: 'hidden',
                            position: { lg: 'relative' },
                            height: { lg: '40vw' },
                        }}
                    >
                        <Box
                            display='flex'
                            justifyContent={{ xs: 'center', lg: 'flex-start' }}
                            padding={{ xs: '42px 0', lg: '42px 5vw' }}
                            marginBottom={{ lg: '200px' }}
                            gap='32px'
                            flexDirection='column'
                        >
                            <Typography 
                                variant='h3'
                                fontSize='28px'
                                fontWeight={800}
                                lineHeight='35px'
                                textAlign={{ xs: 'center', lg: 'end' }}
                                color='#52584D'
                                padding='0 32px'
                            >
                                What Problem Are We Working On?
                            </Typography>
                            <Typography
                                variant='body1'
                                fontSize='16px'
                                fontWeight={400}
                                lineHeight='22px'
                                textAlign={{ xs: 'center', lg: 'end' }}
                                color='#52584D'
                                padding='0 32px'
                            >
                                Lack of air pollution data in the Philippines.
                                <br />
                                <br />
                                Government and regulatory agencies have {isDesktopScreen && <br />} relied on using fixed site air quality monitoring stations (AQMs). {isDesktopScreen && <br />} These stations are expensive, require significant operation {isDesktopScreen && <br />} requirements, and use very sophisticated instruments {isDesktopScreen && <br />} thereby resulting in a sparsely distributed network of AQMs.
                            </Typography>
                        </Box>
                        <Box display='flex' padding='0' margin='0' justifyContent='left'>
                            <Box
                                height={{ xs: '344px', lg: '40vw' }}
                                padding='0'
                                position={{ lg: 'absolute' }}
                                top={{ lg: '0' }}
                                left={{ lg: '5vw' }}
                            >
                                <Box 
                                    component='img'
                                    alt='CARE Node'
                                    src={SensorNodeDeployed5}
                                    sx={{ 
                                        height: { xs: '344px', lg: '100%' },
                                        objectFit: 'cover'
                                    }}
                                />
                            </Box>
                            {isDesktopScreen ? <Box width='100%' position='relative' left='20vw' display='flex' flexDirection='column' gap='3px'>
                                <Typography fontSize={{ xs: '16px', md: '18px' }} fontStyle='italic' fontWeight={600} lineHeight='22px' color='#52584D'>
                                    Only 23% of the PH population <br /> is within 5km of an air quality <br /> monitoring device.
                                </Typography>
                                <Typography fontSize={{ xs: '12px', md: '14px' }} fontStyle='italic' fontWeight={300} lineHeight='22px' color='#52584D'>
                                    Source: Center for Research on Energy and Clean Air (2021)
                                </Typography>
                                <Box 
                                    component='img'
                                    src={DotsAccent2}
                                    sx={{ 
                                        width: '50vw',
                                        opacity: '0.3',
                                        top: '-250%',
                                        right: '150px',
                                    }}
                                    position='absolute'
                                />
                            </Box> :
                            <Box position='relative'>
                                <Box 
                                    component='img'
                                    src={DotsAccent2}
                                    sx={{ 
                                        width: '501px',
                                        opacity: '0.3',
                                        maxHeight: '100%',
                                        top: '0',
                                        right: '0',
                                        transform: { xs: 'translate(50%, -30px)', md: 'translate(75vw, -30px)' },
                                        clipPath: 'inset(0 45% 0 0)',
                                    }}
                                    position='absolute'
                                />
                                <Box width='100%' position='relative' top='215px' right='25px' display='flex' flexDirection='column' gap='3px'>
                                    <Typography fontSize={{ xs: '16px', md: '18px' }} fontStyle='italic' fontWeight={600} lineHeight='22px' color='#52584D'>
                                        Only 23% of the PH population is within 5km of an air quality monitoring device.
                                    </Typography>
                                    <Typography fontSize={{ xs: '12px', md: '14px' }} fontStyle='italic' fontWeight={300} lineHeight='22px' color='#52584D'>
                                        Source: Center for Research on Energy and Clean Air (2021)
                                    </Typography>
                                </Box>
                            </Box>}
                        </Box>
                    </Container>
                </Element>
                <Element name="importance">
                    {isDesktopScreen && <Container
                        sx={{
                            padding: 0,
                            display: 'flex',
                            flexDirection: { xs: 'column', lg: 'row' },
                            justifyContent: 'flex-start',
                            bgcolor: { lg: '#FAF6EA' },
                            minWidth: '100vw',
                        }}
                    >
                        <Container 
                            maxWidth={false}
                            disableGutters
                            sx={{ 
                                pt: 5, 
                                pb: 5, 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignContent: 'center',
                                bgcolor: '#FAF6EA',
                                pr: 5,
                                pl: { xs: 5, lg: 12 },
                                gap: '24px',
                                width: { lg: '50%' }
                            }}
                        >
                            <Typography
                                variant='h3'
                                color='#52584D'
                                textAlign={{ xs: 'right', md: 'left' }}
                                fontSize='28px'
                                fontWeight={800}
                            >
                                Why Is This Problem Important?
                            </Typography>
                            <Box height='100%' display='flex' flexDirection='column' justifyContent='center'>
                                <Typography
                                    variant='body1'
                                    color='#52584D'
                                    textAlign={{ xs: 'right', md: 'left' }}
                                    fontSize='16px'
                                    fontWeight={400}
                                    lineHeight='22px'
                                >
                                    Air pollution has a direct impact on human {isDesktopScreen && <br />} health. Without data, we cannot make {isDesktopScreen && <br />} informed decisions and appropriate actions {isDesktopScreen && <br />} to address poor air quality. 
                                    <br />
                                    <br />
                                    We need to generate more air pollution data {isDesktopScreen && <br />} to develop more accurate air pollution {isDesktopScreen && <br />} concentration maps, develop models to {isDesktopScreen && <br />} forecast and identify the time and areas {isDesktopScreen && <br />} where levels of air pollution are worst, and {isDesktopScreen && <br />} help craft and assess the effectiveness of {isDesktopScreen && <br />} air pollution mitigation programs {isDesktopScreen && <br />} in the country.
                                </Typography>
                            </Box>
                        </Container>
                        <Container 
                            maxWidth={false}
                            disableGutters
                            sx={{ 
                                pt: 5, 
                                pb: 5, 
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'center', 
                                alignContent: 'center',
                                gap: '43px',
                                position: 'relative',
                                width: '50%',
                            }}
                        >
                            <Box
                                component='img'
                                src={DotsAccent}
                                sx={{
                                    maxHeight: '100%',
                                    opacity: 0.5,
                                    top: { xs: '100px', md: '60px' },
                                    right: '0',
                                    transform: { xs: 'translateX(30%)', md: 'translateX(-35%)' },
                                    objectFit: 'cover',
                                    width: { xs: '200%', md: '50%' },
                                }}
                                position='absolute'
                            />
                            <Typography variant='h3' color='#52584D' textAlign='center' fontSize='28px' fontWeight={800}>Who Will Benefit?</Typography>
                            <Container 
                                maxWidth={false}
                                disableGutters
                                sx={{ 
                                    pt: 5, 
                                    pb: 5,
                                    flexDirection: 'column', 
                                    justifyContent: 'center', 
                                    alignContent: 'center',
                                    alignItems: { xs: 'flex-start', lg: 'center' },
                                    margin: 0,
                                    display: 'inline-block',
                                    overflow: 'hidden',
                                    width: { xs: '100vw', lg: '50vw' },
                                    transform: { xs: 'transformX(-50%)', lg: 'none' }
                                }}
                            >
                                <Box sx={{ padding: '0' }} height='fit-content' display={{ md: 'flex' }} justifyContent='center'>
                                    <Carousel animation='slide' duration='500' interval='4000' sx={{ padding: '0 30px 10px 30px', width: { md: '50vw' } }}>
                                        {Benefits.map((benefit, index) => {
                                            return (
                                                <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Card 
                                                        elevation={2} 
                                                        key={`${benefit.name}-${index}`} 
                                                        sx={{ 
                                                            display: 'flex', 
                                                            justifyContent: 'center',
                                                            height: { xs: '355px', md: '355px' },
                                                            width: { xs: '100%', md: '30vw' },
                                                        }}
                                                    >
                                                        <CardActionArea>
                                                            <Box
                                                                component='img'
                                                                loading='lazy'
                                                                sx={{
                                                                    display: 'block',
                                                                    alignContent: 'center',
                                                                    width: { xs: `${!!benefit.flag ? '130%' : '100%'}`, md: '30vw' },
                                                                    objectFit: 'cover',
                                                                }}
                                                                src={benefit.image}
                                                                alt={benefit.name}
                                                                height={{ xs: '231px', md: '265px' }}
                                                            />
                                                            <CardContent>
                                                                <Typography 
                                                                    component='div'
                                                                    fontSize='16px'
                                                                    fontWeight={800}
                                                                    letterSpacing='0.15px'
                                                                >
                                                                    {benefit.name}
                                                                </Typography>
                                                                <Typography fontSize='14px' fontWeight={400} lineHeight='22px'>
                                                                    {benefit.description}
                                                                </Typography>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </Container>
                                            )
                                        })}
                                    </Carousel>
                                </Box>
                            </Container>
                        </Container>
                    </Container>}
                    {!isDesktopScreen && <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            bgcolor: '#FAF6EA',
                            pr: 5,
                            pl: 5,
                            gap: '24px',
                        }}
                    >
                        <Typography
                            variant='h3'
                            color='#52584D'
                            textAlign={{ xs: 'right', md: 'center' }}
                            fontSize='28px'
                            fontWeight={800}
                        >
                            Why Is This Problem Important?
                        </Typography>
                        <Typography variant='body1' color='#52584D' textAlign={{ xs: 'right', md: 'center' }} fontSize='16px' fontWeight={400} lineHeight='22px'>
                            Air pollution has a direct impact on human health. Without data, we cannot make informed decisions and appropriate actions to address poor air quality. 
                            <br />
                            <br />
                            We need to generate more air pollution data to develop more accurate air pollution concentration maps, develop models to forecast and identify the time and areas where levels of air pollution are worst, and help craft and assess the effectiveness of air pollution mitigation programs in the country.
                        </Typography>
                    </Container>}
                    {!isDesktopScreen && <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            gap: '43px',
                            position: 'relative',
                        }}
                    >
                        <Box
                            component='img'
                            src={DotsAccent}
                            sx={{
                                maxHeight: '100%',
                                opacity: 0.5,
                                top: { xs: '100px', md: '60px' },
                                right: '0',
                                transform: { xs: 'translateX(30%)', md: 'translateX(-35%)' },
                                objectFit: 'cover',
                                width: { xs: '200%', md: '50%' },
                            }}
                            position='absolute'
                        />
                        <Typography variant='h3' color='#52584D' textAlign='center' fontSize='28px' fontWeight={800}>Who Will Benefit?</Typography>
                        <Container 
                            maxWidth={false}
                            disableGutters
                            sx={{ 
                                pt: 5, 
                                pb: 5,
                                flexDirection: 'column', 
                                justifyContent: 'center', 
                                alignContent: 'center',
                                margin: 0,
                                display: 'inline-block',
                                overflow: 'hidden',
                            }}
                        >
                            <Box sx={{ padding: '0' }} height='fit-content' display={{ md: 'flex' }} justifyContent='center'>
                                <Carousel animation='slide' duration='500' interval='4000' sx={{ padding: '0 30px 10px 30px', width: { md: '50vw' } }}>
                                    {Benefits.map((benefit, index) => {
                                        return (
                                            <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Card 
                                                    elevation={2} 
                                                    key={`${benefit.name}-${index}`} 
                                                    sx={{ 
                                                        display: 'flex', 
                                                        justifyContent: 'center',
                                                        height: { xs: '355px', md: '355px' },
                                                        width: { xs: '100%', md: '30vw' },
                                                    }}
                                                >
                                                    <CardActionArea>
                                                        <Box
                                                            component='img'
                                                            loading='lazy'
                                                            sx={{
                                                                display: 'block',
                                                                alignContent: 'center',
                                                                width: { xs: `${!!benefit.flag ? '130%' : '100%'}`, md: '30vw' },
                                                                objectFit: 'cover',
                                                            }}
                                                            src={benefit.image}
                                                            alt={benefit.name}
                                                            height={{ xs: '231px', md: '265px' }}
                                                        />
                                                        <CardContent>
                                                            <Typography 
                                                                component='div'
                                                                fontSize='16px'
                                                                fontWeight={800}
                                                                letterSpacing='0.15px'
                                                            >
                                                                {benefit.name}
                                                            </Typography>
                                                            <Typography fontSize='14px' fontWeight={400} lineHeight='22px'>
                                                                {benefit.description}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Container>
                                        )
                                    })}
                                </Carousel>
                            </Box>
                        </Container>
                    </Container>}
                </Element>
                <Element name="solutions">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            bgcolor: '#FAF6EA',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
                            pr: 7,
                            pl: 7,
                            gap: '24px',
                        }}
                    >
                        <Typography variant='h3' color='#52584D' textAlign='center' fontSize='28px' fontWeight={800}>How Are We Solving It?</Typography>
                        {isDesktopScreen ? <Box display='flex' flexDirection='row' gap='80px' alignItems='center'>
                            <Box width='50%' display='flex' flexDirection='row' justifyContent='flex-end'>
                                <Card
                                    sx={{ 
                                        borderRadius: '10px',
                                        backgroundColor: 'lightgray 50%',
                                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                        margin: '25px 0 10px 0',
                                        width: { xs: 'auto', md: '500px' },
                                    }}
                                >
                                    <Box 
                                        height={{ md: '350px', xs: '195px' }}
                                        sx={{ background: `url(${SensorNodeDeployed4})`, backgroundPosition: 'center', backgroundSize: 'cover' }} 
                                    />
                                </Card>
                            </Box>
                            <Box width='5%' display='flex' flexDirection='row' justifyContent='center' />
                            <Box width='45%' display='flex' flexDirection='row' justifyContent='flex-start'>
                                <Typography
                                    variant='body1'
                                    color='#52584D'
                                    textAlign={{ xs: 'center', lg: 'left' }}
                                    fontSize='16px'
                                    fontWeight={400}
                                    lineHeight='22px'
                                >
                                    Project 1 focuses on the design and <br /> deployment of small air pollution sensor <br /> nodes to measure air pollutants, aerosols, <br /> particulate matter, and gases.
                                </Typography>
                            </Box>
                        </Box> :
                        <Box>
                            <Card
                                sx={{ 
                                    borderRadius: '10px',
                                    backgroundColor: 'lightgray 50%',
                                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                    margin: '25px 0 10px 0',
                                }}
                            >
                                <Box 
                                    height={{ md: '600px', xs: '195px' }} 
                                    sx={{ background: `url(${SensorNodeDeployed4})`, backgroundPosition: 'center', backgroundSize: 'cover' }} 
                                />
                            </Card>
                            <Typography variant='body1' color='#52584D' textAlign='center' fontSize='16px' fontWeight={400} lineHeight='22px'>
                                Project 1 focuses on the design and deployment of small air pollution sensor nodes to measure air pollutants, aerosols, particulate matter, and gases.
                            </Typography>
                        </Box>}
                        {isDesktopScreen ? <Box display='flex' flexDirection='row' gap='80px' alignItems='center'>
                            <Box width='50%' display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center' alignContent='center'>
                                <Typography variant='body1' color='#52584D' textAlign='right' fontSize='16px' fontWeight={400} lineHeight='22px'>
                                    An eTraffic video capture system is also <br /> being developed to provide information on <br /> traffic such as average traffic speed, traffic <br /> volume, and types of vehicles on the road. <br /> These data will be valuable in further <br /> understanding the mobile sources of air <br /> pollution by correlating eTraffic and <br /> sensor node data.
                                </Typography>
                            </Box>
                            <Box width='5%' display='flex' flexDirection='row' justifyContent='center' />
                            <Box width='45%' display='flex' flexDirection='row' justifyContent='flex-start' alignContent='center'>
                                <Card
                                    sx={{ 
                                        borderRadius: '10px',
                                        backgroundColor: 'lightgray 50%',
                                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                        margin: '60px 0 10px 0',
                                        width: { xs: 'auto', md: '450px' },
                                    }}
                                >
                                    <Box
                                        height={{ md: '300px', xs: '195px' }}
                                        sx={{ background: `url(${eTraffic3})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
                                    />
                                </Card>
                            </Box>
                        </Box> :
                        <Box>
                            <Card
                                sx={{ 
                                    borderRadius: '10px',
                                    backgroundColor: 'lightgray 50%',
                                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                    margin: '60px 0 10px 0',
                                }}
                            >
                                <Box
                                    height={{ md: '800px', xs: '195px' }}
                                    sx={{ background: `url(${eTraffic3})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
                                />
                            </Card>
                            <Typography variant='body1' color='#52584D' textAlign='center' fontSize='16px' fontWeight={400} lineHeight='22px'>
                                An eTraffic video capture system is also being developed to provide information on traffic such as average traffic speed, traffic volume, and types of vehicles on the road. These data will be valuable in further understanding the mobile sources of air pollution by correlating eTraffic and sensor node data.
                            </Typography>
                        </Box>}
                        {isDesktopScreen ? <Box display='flex' flexDirection='row' gap='80px' alignItems='center'>
                            <Box width='50%' display='flex' flexDirection='row' justifyContent='flex-end'>
                                <Card
                                    sx={{ 
                                        borderRadius: '10px',
                                        backgroundColor: 'lightgray 50%',
                                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                        margin: '60px 0 10px 0',
                                        width: { xs: 'auto', md: '500px' },
                                    }}
                                >
                                    <Box 
                                        height={{ md: '350px', xs: '195px' }}
                                        sx={{ background: `url(${Grafana_UPCARE2})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
                                    />
                                </Card>
                            </Box>
                            <Box width='5%' display='flex' flexDirection='row' justifyContent='center' />
                            <Box width='45%' display='flex' flexDirection='row' justifyContent='flex-start'>
                                <Typography
                                    variant='body1'
                                    color='#52584D'
                                    textAlign={{ xs: 'center', lg: 'left' }}
                                    fontSize='16px'
                                    fontWeight={400}
                                    lineHeight='22px'
                                >
                                    Data collected may be used by various <br /> stakeholders such as LGUs, NGOs, <br /> government agencies, and the public using <br /> web or mobile applications and standard <br /> Internet-of-Things (IoT) protocols.
                                </Typography>
                            </Box>
                        </Box> :
                        <Box>
                            <Card
                                sx={{ 
                                    borderRadius: '10px',
                                    backgroundColor: 'lightgray 50%',
                                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                    margin: '60px 0 10px 0',
                                }}
                            >
                                <Box
                                    height={{ md: '800px', xs: '195px' }}
                                    sx={{ background: `url(${Grafana_UPCARE2})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
                                />
                            </Card>
                            <Typography variant='body1' color='#52584D' textAlign='center' fontSize='16px' fontWeight={400} lineHeight='22px' paddingBottom='20px'>
                                Data collected may be used by various stakeholders such as LGUs, NGOs, government agencies, and the public using web or mobile applications and standard Internet-of-Things (IoT) protocols.
                            </Typography>
                        </Box>}
                    </Container>
                </Element>
                <Element name="contact">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            pr: 5,
                            pl: 5,
                            background: `url(${Backdrop2_Join})`,
                            backgroundSize: 'cover',
                            backgroundColor: 'lightgray 50%',
                        }}
                    >
                        <Typography variant='body1' color='#52584D' textAlign='center' fontSize='18px' fontWeight={600}>Like what you see?</Typography>
                        <Box display='flex' justifyContent='center' padding='22px 0 14px 0'>
                            <ButtonBase
                                LinkComponent={LinkDOM}
                                to={'/contact-us'}
                                sx={{ 
                                    padding: '8px 22px', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    border: '1px solid #E69E4D',
                                    borderRadius: '4px', 
                                    width: '163px',
                                    '&:hover': {
                                        bgcolor: '#CFCBC6'
                                    },
                                }}
                            >
                                <Typography color='#E69E4D' textTransform='uppercase' fontSize={15} fontWeight={500} lineHeight='26px' letterSpacing='0.46px'>
                                    Contact Us
                                </Typography>
                            </ButtonBase>
                        </Box>
                        <Typography variant='body1' color='#52584D' textAlign='center' fontSize='16px' fontWeight={400} lineHeight='30px'>
                            for more information and partnership inquiries.
                        </Typography>
                        <Box display='flex' justifyContent='space-between' paddingTop='53px'>
                            <ButtonBase LinkComponent={LinkDOM} to={'/about-us/projects'}>
                                <Typography color='#E69E4D' fontSize='12px' fontWeight={800} lineHeight='26px' letterSpacing='0.46px' textTransform='uppercase'>
                                    {`< back to projects`}
                                </Typography>
                            </ButtonBase>
                            <ButtonBase LinkComponent={LinkDOM} to={'/about-us/projects/4'}>
                                <Typography color='#E69E4D' fontSize='12px' fontWeight={800} lineHeight='26px' letterSpacing='0.46px' textTransform='uppercase'>
                                    {`project 4 >`}
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Container>
                </Element>
            </div>
            <Footer />
        </>
    );
};

export default People;
