import React from 'react';
import { Helmet } from 'react-helmet';

import { ButtonBase, Card, CardActionArea, CardContent, Container, Typography } from '@mui/material';
import { Link as Element } from 'react-scroll';
import { Link as LinkDOM } from 'react-router-dom';

import Footer from 'ui-component/Footer';

import Backdrop2_Join from '../../../assets/images/Backdrop2_Join.webp';
import VOCSensorDevelopment from '../../../assets/images/VOCSensorDevelopment.webp'
import eBCSensorDevelopment from '../../../assets/images/eBCSensorDevelopment.webp';
import FilterPaperDevelopment from '../../../assets/images/FilterPaperDevelopment.webp'
import DotsAccent from '../../../assets/images/DotsAccent.webp'
import DotsAccent4 from '../../../assets/images/DotsAccent4.webp'
import Icon_Project5 from '../../../assets/images/Icon_Project5.webp'
import VOCSensor from '../../../assets/images/VOCSensor.webp'

import For_Manufacturers from '../../../assets/images/For_Manufacturers.webp'
import For_Entrepreneurs from '../../../assets/images/For_Entrepreneurs.webp'
import For_Government from '../../../assets/images/For_Government.webp'

import { Box } from '@mui/system';
import Carousel from 'react-material-ui-carousel';

const People = () => {

    const Benefits = [
        {
            name: 'Local Manufacturers & Distributors',
            description: "Reduce costs and increase access to AQ sensors and filter media",
            image: For_Manufacturers,
        },
        {
            name: 'Local Entrepreneurs',
            description: "Project 5's AQ sensors could introduce new business opportunities for local entrepreneurs",
            image: For_Entrepreneurs,
        },
        {
            name: 'Government',
            description: "Higher resolution AQ data resulting from affordable sensors will better inform policies and AQ programs",
            image: For_Government,
            flag: true,
        },
    ]

    return (
        <>
            <Helmet>
                <title>Project 5 | UP CARE</title>
            </Helmet>
            <div style={{ overflowX: 'hidden' }}>
                <Element name="start">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 12, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            background: `linear-gradient(180deg, rgba(0, 135, 141, 0.88) 33.33%, rgba(230, 158, 77, 0.70) 64.58%, rgba(0, 173, 181, 0.73) 100%)`,
                            position: 'relative',
                        }}
                    >
                        <Box
                            component='img'
                            src={VOCSensor}
                            sx={{
                                height: '100%',
                                opacity: 0.1,
                                top: '0',
                                right: '0',
                                transform: { xs: 'translateX(40%)' },
                                objectFit: 'cover',
                                display: 'block',
                                width: '170%',
                                alignContent: 'center',
                            }}
                            position='absolute'
                        />
                        <Box padding='0'>
                            <Box 
                                component='img'
                                src={Icon_Project5}
                                sx={{ height: '56px', padding: '0 0 5px 37px' }}
                            />
                            <Typography 
                                variant='h3' 
                                fontSize='14px' 
                                fontWeight={800}
                                color='#FFF'
                                textTransform='uppercase'
                                padding='0 37px'
                            >
                                Project 5
                            </Typography>
                        </Box>
                        <Box padding='21px 37px' display='flex' gap='19px' flexDirection='column'>
                            <Typography variant='h3' color='#FFF' fontSize='20px' fontWeight={800}>
                                Development of Filter Media, eBC, and VOC Sensors for Local Conditions
                            </Typography>
                            <Typography variant='h3' fontSize='14px' fontWeight={700} color='#FFF'>
                                Lead: Louis Angelo Danao, PhD
                            </Typography>
                        </Box>
                        <Box
                            width='90%'
                            sx={{ 
                                background: 'rgba(0, 0, 0, 0.35)', 
                                borderTopRightRadius: '5px', 
                                borderBottomRightRadius: '5px',
                            }}
                        >
                            <Typography variant='h3' color='#FFF' fontSize='20px' fontWeight={800} lineHeight='30px' letterSpacing='1px' padding='20px 33px 20px 48px'>
                                Cost-effective, locally-developed sensors to allow broader air quality monitoring coverage for proper modeling and prediction of pollution levels
                            </Typography>
                        </Box>
                        <Box paddingLeft='37px' paddingTop='17px' display='flex' gap='8px'>
                            <Typography 
                                variant='body1' 
                                padding='6px 9px' 
                                borderRadius='5px' 
                                bgcolor='#E69E4D' 
                                color='#FFF' 
                                fontSize='11px' 
                                fontWeight={400} 
                                lineHeight='10px' 
                                textTransform='uppercase'
                            >
                                On-Going
                            </Typography>
                            <Typography 
                                variant='body1' 
                                padding='6px 9px' 
                                borderRadius='5px' 
                                bgcolor='#E69E4D' 
                                color='#FFF' 
                                fontSize='11px' 
                                fontWeight={400} 
                                lineHeight='10px' 
                                textTransform='uppercase'
                            >
                                TRL 2
                            </Typography>
                            <Typography 
                                variant='body1' 
                                padding='6px 9px' 
                                borderRadius='5px' 
                                bgcolor='#E69E4D' 
                                color='#FFF' 
                                fontSize='11px' 
                                fontWeight={400} 
                                lineHeight='10px' 
                                textTransform='uppercase'
                            >
                                IP Protection In-Progress
                            </Typography>
                        </Box>
                    </Container>
                </Element>
                <Element name="problem">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5,
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
                            pl: 0,
                            pr: 0,
                            display: 'inline-flex',
                            overflow: 'hidden',
                        }}
                    >
                        <Box display='flex' justifyContent='center' padding='42px 0' gap='32px' flexDirection='column'>
                            <Typography 
                                variant='h3'
                                fontSize='28px'
                                fontWeight={800}
                                lineHeight='35px'
                                textAlign='center'
                                color='#52584D'
                                padding='0 32px'
                            >
                                What Problem Are We Working On?
                            </Typography>
                            <Typography variant='body1' fontSize='16px' fontWeight={400} lineHeight='22px' textAlign='center' color='#52584D' padding='0 32px'>
                                Lack of locally-developed and affordable air quality sensors.
                                <br />
                                <br />
                                Using expensive sensors do increase confidence in the data produced. However, this poses a drawback on the number of sensors that can be deployed when high resolution, real-time data is required for on-demand air quality information services. Development of reliable and accurate sensors that are affordable is necessary to address the need for more sensors deployed in the urban landscape.
                            </Typography>
                        </Box>
                        <Box display='flex' padding='0' margin='0' justifyContent='left'>
                            <Box position='relative'>
                                <Box 
                                    component='img'
                                    src={DotsAccent4}
                                    sx={{ 
                                        width: { xs: 'default', md: 'default' },
                                        opacity: '0.3',
                                        maxHeight: '100%',
                                        top: '0',
                                        right: '0',
                                        transform: { xs: 'translate(-15%, 10px)', md: 'translate(-220%, -10px)' },
                                    }}
                                    position='absolute'
                                />
                                <Box
                                    width='60%'
                                    display='flex'
                                    flexDirection='column'
                                    gap='3px'
                                    sx={{ transform: { xs: 'translate(50%, -19%)', md: 'translate(66%, -30%)' } }}
                                    paddingTop='100px'>
                                    <Typography
                                        fontSize={{ xs: '16px', md: '18px' }}
                                        fontStyle='italic'
                                        fontWeight={600}
                                        lineHeight='22px'
                                        color='#52584D'
                                        textAlign='right'
                                        paddingBottom='2px'
                                    >
                                        “We enjoin our local government units to collaborate with our researchers in finding solutions in making our air cleaner through technologies brought about by research and development.”
                                    </Typography>
                                    <Typography
                                        fontSize={{ xs: '12px', md: '14px' }}
                                        fontStyle='italic'
                                        fontWeight={300}
                                        color='#52584D'
                                        textAlign='right'
                                    >
                                        Dr. Enrico Paringit
                                    </Typography>
                                    <Typography
                                        fontSize={{ xs: '12px', md: '14px' }}
                                        fontStyle='italic'
                                        fontWeight={300}
                                        color='#52584D'
                                        textAlign='right'
                                    >
                                        Executive Director, DOST-PCIEERD
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Element>
                <Element name="importance">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            bgcolor: '#FAF6EA',
                            pr: 5,
                            pl: 5,
                            gap: '24px',
                        }}
                    >
                        <Typography
                            variant='h3'
                            color='#52584D'
                            textAlign={{ xs: 'right', md: 'center' }}
                            fontSize='28px'
                            fontWeight={800}
                        >
                            Why Is This Problem Important?
                        </Typography>
                        <Typography variant='body1' color='#52584D' textAlign={{ xs: 'right', md: 'center' }} fontSize='16px' fontWeight={400} lineHeight='22px'>
                            It is important to develop local capability for sensor development to widen the adoption of affordable sensors. This wider network will provide the necessary data resolution for proper modeling and prediction of air pollution levels that more expensive, highly accurate sensors cannot address.
                            <br />
                            <br />
                            The need for locally designed sensors that meet the demand of local conditions is imperative. Local conditions are different from other countries due to unique driving conditions, local regulations, and vehicle density, among other factors.
                        </Typography>
                    </Container>
                </Element>
                <Element name="benefit">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            gap: '43px',
                            position: 'relative',
                        }}
                    >
                        <Box
                            component='img'
                            src={DotsAccent}
                            sx={{
                                maxHeight: '100%',
                                opacity: 0.5,
                                top: { xs: '100px', md: '60px' },
                                right: '0',
                                transform: { xs: 'translateX(30%)', md: 'translateX(-35%)' },
                                objectFit: 'cover',
                                width: { xs: '200%', md: '60%' },
                            }}
                            position='absolute'
                        />
                        <Typography variant='h3' color='#52584D' textAlign='center' fontSize='28px' fontWeight={800}>Who Will Benefit?</Typography>
                        <Container 
                            maxWidth={false}
                            disableGutters
                            sx={{ 
                                pt: 5, 
                                pb: 5,
                                flexDirection: 'column', 
                                justifyContent: 'center', 
                                alignContent: 'center',
                                margin: 0,
                                display: 'inline-block',
                                overflow: 'hidden',
                            }}
                        >
                            <Box sx={{ padding: '0' }} height='fit-content' display={{ md: 'flex' }} justifyContent='center'>
                            <Carousel animation='slide' duration='500' interval='4000' sx={{ padding: '0 30px 10px 30px', width: { md: '50vw' } }}>
                                {Benefits.map((benefit, index) => {
                                    return (
                                        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Card 
                                                elevation={2} 
                                                key={`${benefit.name}-${index}`} 
                                                sx={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'center',
                                                    height: { xs: '355px', md: '60vh' },
                                                    width: { xs: '100%', md: '28vw' },
                                                }}
                                            >
                                                <CardActionArea>
                                                    <Box
                                                        component='img'
                                                        loading='lazy'
                                                        sx={{
                                                            display: 'block',
                                                            alignContent: 'center',
                                                            width: `${benefit.flag ? '130%' : '100%'}`,
                                                            objectFit: 'cover',
                                                        }}
                                                        src={benefit.image}
                                                        alt={benefit.name}
                                                        height={{ xs: '231px', md: '83%' }}
                                                    />
                                                    <CardContent>
                                                        <Typography 
                                                            component='div'
                                                            fontSize='16px'
                                                            fontWeight={800}
                                                            letterSpacing='0.15px'
                                                        >
                                                            {benefit.name}
                                                        </Typography>
                                                        <Typography fontSize='14px' fontWeight={400} lineHeight='22px'>
                                                            {benefit.description}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Container>
                                    )
                                })}
                            </Carousel>
                            </Box>
                        </Container>
                    </Container>
                </Element>
                <Element name="solutions">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            bgcolor: '#FAF6EA',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
                            pr: 3,
                            pl: 3,
                            gap: '24px',
                        }}
                    >
                        <Typography variant='h3' color='#52584D' textAlign='center' fontSize='28px' fontWeight={800}>How Are We Solving It?</Typography>
                        <Card
                            sx={{ 
                                borderRadius: '10px',
                                backgroundColor: 'lightgray 50%',
                                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                margin: '25px 0 10px 0',
                            }}
                        >
                            <Box 
                                height={{ md: '600px', xs: '195px' }} 
                                sx={{ background: `url(${FilterPaperDevelopment})`, backgroundPosition: 'center', backgroundSize: 'cover' }} 
                            />
                        </Card>
                        <Typography variant='body1' color='#52584D' textAlign='center' fontSize='16px' fontWeight={400} lineHeight='22px'>
                            Project 5 is developing a locally-fabricated PM filter paper and PM filtering system for the effective and efficient collection of  particulate matter. Once developed, it can also be used for P5's eBC and VOC sensors.
                        </Typography>
                        <Card
                            sx={{ 
                                borderRadius: '10px',
                                backgroundColor: 'lightgray 50%',
                                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                margin: '60px 0 10px 0',
                            }}
                        >
                            <Box
                                height={{ md: '800px', xs: '195px' }}
                                sx={{ background: `url(${eBCSensorDevelopment})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
                            />
                        </Card>
                        <Typography variant='body1' color='#52584D' textAlign='center' fontSize='16px' fontWeight={400} lineHeight='22px'>
                            Equivalent black carbon (eBC) is a component of particulate matter that is emitted from various sources such as biomass burning, traffic-related emissions, and coal combustion. Project 5 is developing an eBC sensor with a compact and weatherproof design tailored for the Philippine urban environment.
                        </Typography>
                        <Card
                            sx={{ 
                                borderRadius: '10px',
                                backgroundColor: 'lightgray 50%',
                                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                margin: '60px 0 10px 0',
                            }}
                        >
                            <Box
                                height={{ md: '800px', xs: '195px' }}
                                sx={{ background: `url(${VOCSensorDevelopment})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
                            />
                        </Card>
                        <Typography variant='body1' color='#52584D' textAlign='center' fontSize='16px' fontWeight={400} lineHeight='22px' paddingBottom='20px'>
                            Volatile Organic Compounds (VOC), are serious health hazards that usually result from vehicular emissions, incomplete combustion of fuel, household cooking, cleaning, and heating, etc. Project 5 is developing a sensor to monitor VOCs, specifically the Benzene, Toluene, and Xylene (BTX) group.
                        </Typography>
                    </Container>
                </Element>
                <Element name="contact">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            pr: 5,
                            pl: 5,
                            background: `url(${Backdrop2_Join})`,
                            backgroundSize: 'cover',
                            backgroundColor: 'lightgray 50%',
                        }}
                    >
                        <Typography variant='body1' color='#52584D' textAlign='center' fontSize='18px' fontWeight={600}>Like what you see?</Typography>
                        <Box display='flex' justifyContent='center' padding='22px 0 14px 0'>
                            <ButtonBase
                                LinkComponent={LinkDOM}
                                to={'/contact-us'}
                                sx={{ 
                                    padding: '8px 22px', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    border: '1px solid #E69E4D',
                                    borderRadius: '4px', 
                                    width: '163px',
                                    '&:hover': {
                                        bgcolor: '#CFCBC6'
                                    },
                                }}
                            >
                                <Typography color='#E69E4D' textTransform='uppercase' fontSize={15} fontWeight={500} lineHeight='26px' letterSpacing='0.46px'>
                                    Contact Us
                                </Typography>
                            </ButtonBase>
                        </Box>
                        <Typography variant='body1' color='#52584D' textAlign='center' fontSize='16px' fontWeight={400} lineHeight='30px'>
                            for more information and partnership inquiries.
                        </Typography>
                        <Box display='flex' justifyContent='space-between' paddingTop='53px'>
                            <ButtonBase LinkComponent={LinkDOM} to={'/about-us/projects/4'}>
                                <Typography color='#E69E4D' fontSize='12px' fontWeight={800} lineHeight='26px' letterSpacing='0.46px' textTransform='uppercase'>
                                    {`< project 4`}
                                </Typography>
                            </ButtonBase>
                            <ButtonBase LinkComponent={LinkDOM} to={'/about-us/projects'}>
                                <Typography color='#E69E4D' fontSize='12px' fontWeight={800} lineHeight='26px' letterSpacing='0.46px' textTransform='uppercase'>
                                    {`back to projects >`}
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Container>
                </Element>
            </div>
            <Footer />
        </>
    );
};

export default People;
