import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// // utilities routing
const AirQuality101 = Loadable(lazy(() => import('views/sample-page')));
const Publications = Loadable(lazy(() => import('views/sample-page')));
const News = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const ResourcesRoutes = {
    path: '/resources',
    element: <MainLayout />,
    children: [
        {
            path: 'air-quality-101',
            element: <AirQuality101 />,
        },
        {
            path: 'publications',
            element: <Publications />,
        },
        {
            path: 'news',
            element: <News />,
        },
    ],
};

export default ResourcesRoutes;
