import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// // utilities routing
const PrivacyPolicy = Loadable(lazy(() => import('views/privacy-policy')));

const OtherRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'privacy-policy',
            element: <PrivacyPolicy />,
        },
    ],
};

export default OtherRoutes;
