import React from 'react';
import { Helmet } from 'react-helmet';

import { ButtonBase, Card, CardActionArea, CardContent, Container, Typography } from '@mui/material';
import { Link as Element } from 'react-scroll';
import { Link as LinkDOM } from 'react-router-dom';

import Footer from 'ui-component/Footer';

import Backdrop2_Join from '../../../assets/images/Backdrop2_Join.webp';
import Grafana_UPCARE2 from '../../../assets/images/Grafana_UPCARE2.webp'
import Board from '../../../assets/images/Board.webp'
import IAQ from '../../../assets/images/IAQ.webp'
import DotsAccent from '../../../assets/images/DotsAccent.webp'
import DotsAccent3 from '../../../assets/images/DotsAccent3.webp'
import Icon_Project4 from '../../../assets/images/Icon_Project4.webp'
import Indoor_Office from '../../../assets/images/Indoor_Office.webp'

import CFD from '../../../assets/videos/CFD.mp4';

import For_Industry from '../../../assets/images/For_Industry.webp'
import For_Schools from '../../../assets/images/For_Schools.webp'
import For_Individuals from '../../../assets/images/For_Individuals.webp'

import { Box } from '@mui/system';
import Carousel from 'react-material-ui-carousel';

const People = () => {

    const Benefits = [
        {
            name: 'Industry',
            description: "Good IAQ help ensure that employees and customers are not exposed to harmful pollutants",
            image: For_Industry,
        },
        {
            name: 'Schools',
            description: "Good IAQ help ensure that students and teachers have a healthy and safe learning environment",
            image: For_Schools,
        },
        {
            name: 'Individuals',
            description: "From hospitals, malls, to our very own homes, IAQ monitoring will help everyone breathe cleaner air indoors",
            image: For_Individuals,
        },
    ]

    return (
        <>
            <Helmet>
                <title>Project 4 | UP CARE</title>
            </Helmet>
            <div style={{ overflowX: 'hidden' }}>
                <Element name="start">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 12,
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            background: `linear-gradient(180deg, rgba(0, 135, 141, 0.88) 33.33%, rgba(230, 158, 77, 0.70) 64.58%, rgba(0, 173, 181, 0.73) 100%)`,
                            position: 'relative',
                        }}
                    >
                        <Box
                            component='img'
                            src={Indoor_Office}
                            sx={{
                                height: '100%',
                                opacity: 0.1,
                                top: '0',
                                right: '0',
                                transform: { xs: 'translateX(45%)' },
                                clipPath: 'inset(0 45% 0 0)',
                                objectFit: 'cover',
                                display: 'block',
                                width: '190%',
                                alignContent: 'center',
                            }}
                            position='absolute'
                        />
                        <Box padding='0'>
                            <Box 
                                component='img'
                                src={Icon_Project4}
                                sx={{ height: '56px', padding: '0 0 5px 37px' }}
                            />
                            <Typography 
                                variant='h3' 
                                fontSize='14px' 
                                fontWeight={800}
                                color='#FFF'
                                textTransform='uppercase'
                                padding='0 37px'
                            >
                                Project 4
                            </Typography>
                        </Box>
                        <Box padding='21px 37px' display='flex' gap='19px' flexDirection='column'>
                            <Typography variant='h3' color='#FFF' fontSize='20px' fontWeight={800}>
                            Indoor Air Quality Monitoring and Reporting System
                            </Typography>
                            <Typography variant='h3' fontSize='14px' fontWeight={700} color='#FFF'>
                            Lead: Joseph Gerard Reyes, PhD
                            </Typography>
                        </Box>
                        <Box
                            width='90%'
                            sx={{ 
                                background: 'rgba(0, 0, 0, 0.35)', 
                                borderTopRightRadius: '5px', 
                                borderBottomRightRadius: '5px',
                            }}
                        >
                            <Typography variant='h3' color='#FFF' fontSize='20px' fontWeight={800} lineHeight='30px' letterSpacing='1px' padding='20px 33px 20px 48px'>
                                Understanding airflow and contaminant dispersion in indoor environments for safer homes, schools, and workplaces
                            </Typography>
                        </Box>
                        <Box paddingLeft='37px' paddingTop='17px' display='flex' gap='8px'>
                            <Typography 
                                variant='body1' 
                                padding='6px 9px' 
                                borderRadius='5px' 
                                bgcolor='#E69E4D' 
                                color='#FFF' 
                                fontSize='11px' 
                                fontWeight={400} 
                                lineHeight='10px' 
                                textTransform='uppercase'
                            >
                                On-Going
                            </Typography>
                            <Typography 
                                variant='body1' 
                                padding='6px 9px' 
                                borderRadius='5px' 
                                bgcolor='#E69E4D' 
                                color='#FFF' 
                                fontSize='11px' 
                                fontWeight={400} 
                                lineHeight='10px' 
                                textTransform='uppercase'
                            >
                                TRL 3
                            </Typography>
                            <Typography 
                                variant='body1' 
                                padding='6px 9px' 
                                borderRadius='5px' 
                                bgcolor='#E69E4D' 
                                color='#FFF' 
                                fontSize='11px' 
                                fontWeight={400} 
                                lineHeight='10px' 
                                textTransform='uppercase'
                            >
                                IP Protection In-Progress
                            </Typography>
                        </Box>
                    </Container>
                </Element>
                <Element name="problem">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5,
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
                            pl: 0,
                            pr: 0,
                            display: 'inline-flex',
                            overflow: 'hidden',
                        }}
                    >
                        <Box display='flex' justifyContent='center' padding='42px 0' gap='32px' flexDirection='column'>
                            <Typography 
                                variant='h3'
                                fontSize='28px'
                                fontWeight={800}
                                lineHeight='35px'
                                textAlign='center'
                                color='#52584D'
                                padding='0 32px'
                            >
                                What Problem Are We Working On?
                            </Typography>
                            <Typography variant='body1' fontSize='16px' fontWeight={400} lineHeight='22px' textAlign='center' color='#52584D' padding='0 32px'>
                                Inadequate indoor air quality awareness and monitoring in the Philippines.
                                <br />
                                <br />
                                People who stay in confined areas for prolonged periods (such as office workers) face the risk of exposure to indoor air pollutants. These contaminants are undetected by ordinary human senses. Thus, people are unaware of the presence of these harmful compounds and could even ingest them unknowingly when breathing.
                            </Typography>
                        </Box>
                        <Box display='flex' padding='0 0 30px 0' margin='0' justifyContent='left'>
                            <Box
                                height='auto'
                                padding='100px 0 0 0'
                                marginLeft='20px'
                                marginBottom='29px'
                                sx={{ transform: { xs: 'translateY(30%)', md: 'translateY(5%)' } }}
                                position='absolute'
                            >
                                <Box 
                                    component='img'
                                    alt='CARE Node'
                                    src={IAQ}
                                    sx={{ 
                                        height: '122px',
                                        objectFit: 'cover'
                                    }}
                                />
                            </Box>
                            <Box position='relative'>
                                <Box 
                                    component='img'
                                    src={DotsAccent3}
                                    sx={{ 
                                        width: '350px',
                                        opacity: '0.3',
                                        maxHeight: '100%',
                                        top: { md: '-20%' },
                                        right: { xs: '40%', md: '60%' },
                                        transform: 'rotate(-30deg)',
                                    }}
                                    position='absolute'
                                />
                                <Box
                                    maxWidth={{ lg: '100%', md: '70%', xs: '50%' }}
                                    display='flex'
                                    flexDirection='column'
                                    gap='3px'
                                    sx={{
                                        float: 'right',
                                        transform: {
                                            xs: 'translate(-30%, -25%)',
                                            md: 'translate(0, -40%)',
                                            lg: 'translate(20%, -40%)',
                                        },
                                    }}
                                    paddingTop='200px'
                                >
                                    <Typography
                                        fontSize={{ xs: '16px', md: '18px' }}
                                        fontStyle='italic'
                                        fontWeight={600}
                                        lineHeight='22px'
                                        color='#52584D'
                                        textAlign='right'
                                    >
                                        People living in cities spend about 90% of their time indoors, which makes most of the air they breathe "indoor air."
                                    </Typography>
                                    <Typography
                                        fontSize={{ xs: '12px', md: '14px' }}
                                        fontStyle='italic'
                                        fontWeight={300}
                                        lineHeight='22px'
                                        color='#52584D'
                                        textAlign='right'
                                    >
                                        Source: US EPA (2023)
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Element>
                <Element name="importance">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            bgcolor: '#FAF6EA',
                            pr: 5,
                            pl: 5,
                            gap: '24px',
                        }}
                    >
                        <Typography
                            variant='h3'
                            color='#52584D'
                            textAlign={{ xs: 'right', md: 'center' }}
                            fontSize='28px'
                            fontWeight={800}
                        >
                            Why Is This Problem Important?
                        </Typography>
                        <Typography variant='body1' color='#52584D' textAlign={{ xs: 'right', md: 'center' }} fontSize='16px' fontWeight={400} lineHeight='22px'>
                            Poor indoor air quality (IAQ) can lead to several health problems, including respiratory illnesses, allergies, and asthma. By monitoring indoor air quality, we can identify potential problems and take steps to address them before they become serious health hazards.
                            <br />
                            <br />
                            Aside from health effects, poor IAQ can also place owners of indoor facilities at risk for potential lost revenue from losing tenants; there are also issues of tarnished image suffered by the owners and violation of statutory requirements for healthy workplaces.
                        </Typography>
                    </Container>
                </Element>
                <Element name="benefit">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            gap: '43px',
                            position: 'relative',
                        }}
                    >
                        <Box
                            component='img'
                            src={DotsAccent}
                            sx={{
                                maxHeight: '100%',
                                opacity: 0.5,
                                top: { xs: '100px', md: '60px' },
                                right: '0',
                                transform: { xs: 'translateX(30%)', md: 'translateX(-20%)' },
                                objectFit: 'cover',
                                width: { xs: '200%', md: '70vw' },
                            }}
                            position='absolute'
                        />
                        <Typography variant='h3' color='#52584D' textAlign='center' fontSize='28px' fontWeight={800}>Who Will Benefit?</Typography>
                        <Container 
                            maxWidth={false}
                            disableGutters
                            sx={{ 
                                pt: 5, 
                                pb: 5,
                                flexDirection: 'column', 
                                justifyContent: 'center', 
                                alignContent: 'center',
                                margin: 0,
                                display: 'inline-block',
                                overflow: 'hidden',
                            }}
                        >
                            <Box sx={{ padding: '0' }} height='fit-content' display={{ md: 'flex' }} justifyContent='center'>
                                <Carousel animation='slide' duration='500' interval='4000' sx={{ padding: '0 30px 10px 30px', width: { md: '50vw' } }}>
                                    {Benefits.map((benefit, index) => {
                                        return (
                                            <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Card 
                                                    elevation={2} 
                                                    key={`${benefit.name}-${index}`} 
                                                    sx={{ 
                                                        display: 'flex', 
                                                        justifyContent: 'center',
                                                        height: { xs: '355px', md: '60vh' },
                                                        width: { xs: '100%', md: '30vw' },
                                                    }}
                                                >
                                                    <CardActionArea>
                                                        <Box
                                                            component='img'
                                                            loading='lazy'
                                                            sx={{
                                                                display: 'block',
                                                                alignContent: 'center',
                                                                width: { xs: '100%', md: '100%' },
                                                                objectFit: 'cover',
                                                            }}
                                                            src={benefit.image}
                                                            alt={benefit.name}
                                                            height={{ xs: '231px', md: '81%' }}
                                                        />
                                                        <CardContent>
                                                            <Typography 
                                                                component='div'
                                                                fontSize='16px'
                                                                fontWeight={800}
                                                                letterSpacing='0.15px'
                                                            >
                                                                {benefit.name}
                                                            </Typography>
                                                            <Typography fontSize='14px' fontWeight={400} lineHeight='22px'>
                                                                {benefit.description}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Container>
                                        )
                                    })}
                                </Carousel>
                            </Box>
                        </Container>
                    </Container>
                </Element>
                <Element name="solutions">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            bgcolor: '#FAF6EA',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
                            gap: '24px',
                        }}
                    >
                        <Typography
                            variant='h3'
                            color='#52584D'
                            textAlign='center'
                            fontSize='28px'
                            fontWeight={800}
                            padding='0 48px'
                        >
                            How Are We Solving It?
                        </Typography>
                        <Box margin='0' padding='0' width='100vw' display='flex' justifyContent='center'>
                            <Card
                                sx={{ 
                                    borderRadius: '10px',
                                    backgroundColor: 'lightgray 50%',
                                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                    margin: '35px 0 10px 0',
                                    width: { md: '90vw', xs: '90vw' },
                                    height: { md: 'fit-content', xs: '316px' },
                                }}
                            >
                                <video
                                    src={CFD}
                                    autoPlay
                                    loop
                                    muted
                                    style={{
                                        width: '90vw',
                                        height: { md: 'fit-content', xs: '316px' },
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                        zIndex: 0,
                                    }}
                                />
                            </Card>
                        </Box>
                        <Typography variant='body1' color='#52584D' textAlign='center' fontSize='16px' fontWeight={400} lineHeight='22px' padding='0 32px'>
                            Part of effective IAQ monitoring is knowing where to properly place AQ sensors in an indoor environment. Project 4 addresses this problem through modeling techniques that simulate and predict indoor air behavior. 
                        </Typography>
                        <Box margin='0' padding='0' width='100vw' display='flex' justifyContent='center'>
                            <Card
                                sx={{ 
                                    borderRadius: '10px',
                                    backgroundColor: 'lightgray 50%',
                                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                    margin: '35px 0 10px 0',
                                    width: { md: '90vw', xs: '90vw' },
                                }}
                            >
                                <Box
                                    height={{ md: '800px', xs: '195px' }}
                                    width={{ md: '90vw', xs: '90vw' }}
                                    sx={{ background: `url(${Board})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
                                />
                            </Card>
                        </Box>
                        <Typography variant='body1' color='#52584D' textAlign='center' fontSize='16px' fontWeight={400} lineHeight='22px' padding='0 32px'>
                            Project 4 is using the locally-designed IAQ sensors from Project 1, which are capable of detecting NO2, O3, VOC, CO2, Formaldehyde, and PM2.5.
                        </Typography>
                        <Box margin='0' padding='0' width='100vw' display='flex' justifyContent='center'>
                            <Card
                                sx={{ 
                                    borderRadius: '10px',
                                    backgroundColor: 'lightgray 50%',
                                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                    margin: '35px 0 10px 0',
                                    width: { md: '90vw', xs: '90vw' },
                                }}
                            >
                                <Box
                                    height={{ md: '800px', xs: '195px' }}
                                    width={{ md: '90vw', xs: '90vw' }}
                                    sx={{ background: `url(${Grafana_UPCARE2})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
                                />
                            </Card>
                        </Box>
                        <Typography
                            variant='body1'
                            color='#52584D'
                            textAlign='center'
                            fontSize='16px'
                            fontWeight={400}
                            lineHeight='22px'
                            paddingBottom='20px'
                            padding='0 32px'
                        >
                            Data collected may be viewed and used via the IoT platform being developed by Project 1. Data will be made available to various stakeholders such as LGUs, NGOs, government agencies, and the public.
                        </Typography>
                    </Container>
                </Element>
                <Element name="contact">
                    <Container 
                        maxWidth={false}
                        disableGutters
                        sx={{ 
                            pt: 5, 
                            pb: 5, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center', 
                            alignContent: 'center',
                            pr: 5,
                            pl: 5,
                            background: `url(${Backdrop2_Join})`,
                            backgroundSize: 'cover',
                            backgroundColor: 'lightgray 50%',
                        }}
                    >
                        <Typography variant='body1' color='#52584D' textAlign='center' fontSize='18px' fontWeight={600}>Like what you see?</Typography>
                        <Box display='flex' justifyContent='center' padding='22px 0 14px 0'>
                            <ButtonBase
                                LinkComponent={LinkDOM}
                                to={'/contact-us'}
                                sx={{ 
                                    padding: '8px 22px', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    border: '1px solid #E69E4D',
                                    borderRadius: '4px', 
                                    width: '163px',
                                    '&:hover': {
                                        bgcolor: '#CFCBC6'
                                    },
                                }}
                            >
                                <Typography color='#E69E4D' textTransform='uppercase' fontSize={15} fontWeight={500} lineHeight='26px' letterSpacing='0.46px'>
                                    Contact Us
                                </Typography>
                            </ButtonBase>
                        </Box>
                        <Typography variant='body1' color='#52584D' textAlign='center' fontSize='16px' fontWeight={400} lineHeight='30px'>
                            for more information and partnership inquiries.
                        </Typography>
                        <Box display='flex' justifyContent='space-between' paddingTop='53px'>
                            <ButtonBase LinkComponent={LinkDOM} to={'/about-us/projects/1'}>
                                <Typography color='#E69E4D' fontSize='12px' fontWeight={800} lineHeight='26px' letterSpacing='0.46px' textTransform='uppercase'>
                                    {`< Project 1`}
                                </Typography>
                            </ButtonBase>
                            <ButtonBase LinkComponent={LinkDOM} to={'/about-us/projects/5'}>
                                <Typography color='#E69E4D' fontSize='12px' fontWeight={800} lineHeight='26px' letterSpacing='0.46px' textTransform='uppercase'>
                                    {`project 5 >`}
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Container>
                </Element>
            </div>
            <Footer />
        </>
    );
};

export default People;
