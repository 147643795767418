// ==============================|| followUs MENU ITEMS ||============================== //

const followUs = {
    id: 'follow-us',
    title: 'Follow Us',
    type: 'group',
    children: [
        {
            id: 'contact-us',
            title: 'Contact Us',
            type: 'item',
            url: '/contact-us',
            breadcrumbs: true
        }
    ]
};

export default followUs;
